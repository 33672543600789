<template>
  <div>
    <h2 class="hi text-success"><strong>CBP 1.0</strong> est un Mini-ERP</h2>
    <hr />
    <b-img
      left
      height="175"
      :src="require('@/assets/images/erp-synthese.png')"
      alt="CBP Application Modules"
    />
    <p>
      <b class="text-warning">Mais qu'est-ce que cela signifie ?</b>
      ERP « Enterprise Resource Planning » (progiciel de gestion intégré)<br />
      simplifie tous les processus clés nécessaires pour gérer une organisation
      à savoir : Projet, Finance, RH, Logistique, Services, Achats, etc... L'ERP
      permet la gestion efficace de tous ces processus dans le cadre d'un
      système intégré. On parle souvent du système d'enregistrement de
      l'organisation. Pourtant, les systèmes ERP d'aujourd'hui sont tout sauf
      basiques et ressemblent peu aux ERP que l'on a connus il y a plusieurs
      décennies. Ils sont désormais fournis via le cloud et utilisent les
      technologies les plus récentes, telles que l'Intelligence Artificielle
      (IA) et le Machine Learning, pour fournir une automatisation intelligente,
      une efficacité accrue et des insights instantanés à l'échelle de
      l'organisation. Un logiciel ERP moderne connecte également les opérations
      internes aux partenaires et réseaux du monde entier, offrant ainsi aux
      organisation la collaboration, l'agilité et la rapidité dont elles ont
      besoin.
    </p>
    <br />
    <br />
    <h2 class="hi text-primary">Pourquoi l'ERP est-il important ?</h2>
    <hr />
    <p>
      Parfois décrit comme « le système nerveux central d'une organisation », un
      système ERP fournit l'automatisation, l'intégration et l'intelligence
      essentielles à l'exécution efficace de toutes les opérations de gestion
      quotidiennes. La plupart ou la totalité des données d'une organisation
      doivent résider dans le système ERP pour fournir une version unique de la
      réalité à l'échelle de l'organisation. La finance a besoin d'un ERP pour
      rapidement clôturer les comptes. La logistique s'appuie sur un logiciel
      ERP fiable pour fournir les options adéquats aux parties prenantes dans
      les délais impartis. La comptabilité a besoin d'un système ERP pour payer
      les fournisseurs correctement et dans les délais. La direction a besoin
      d'une visibilité instantanée sur les performances de l'organisation pour
      prendre des décisions en temps opportun. Et enfin, les banques, les
      partenaires et les agents de l'organisation ont besoin d'enregistrements
      financiers précis, et comptent donc sur des données et des analyses
      fiables, que le système ERP est capable d'offrir. L'importance des
      logiciels ERP pour les organisations est illustrée par leur taux
      d'adoption croissant.
    </p>
  </div>
</template>
<script>
import { BImg } from "bootstrap-vue";

export default {
  components: {
    BImg,
  },
  setup() {},
  mounted() {},
  methods: {},
};
</script>
<style>
.erp_footer_image {
  width: 100%;
}
</style>
